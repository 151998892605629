import {httpClient} from "@/httpClient";
import {store} from "@/store";

// Поиск артикулов по имени
function findArticleByName(payload: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/search?nameLike=${payload}`).then(response => response.data)
}

function newFindArticleByName(articleName: any) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/search/v2?nameLike=${articleName}`).then(response => response.data)
}

// Поиск артикулов по имени в товарной группе
function findArticleByNameAndProductGroup(nameLike: string, productGroupId: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}?nameLike=${nameLike}&productGroupId=${productGroupId}`).then(response => response.data)
}

// Получение артикулов
function getArticles() {
  let pageable = store.getters.getPageable;
  let sort = store.getters.getSort;
  return httpClient.get(`/article/${localStorage.getItem('cid')}?page=${pageable.page}&size=${pageable.size}&sort=article,${sort}`).then(response => response.data)
}

// Карточка артикула (изменение временного статуса)
function changeTempStatus(id: string, payload: object) {
  return httpClient.patch(`/article/${localStorage.getItem('cid')}/${id}/status`, payload).then(response => response.data)
}

// Карточка артикула (изменение статуса)
function changeStatus(id: string, status: string) {
  return httpClient.patch(`/article/${localStorage.getItem('cid')}/${id}/status`, {
    status
  }).then(response => response.data)
}

// Карточка артикула (обновление информации)
function updateArticle(id: string, payload: object) {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/${id}`, payload).then(response => response.data)
}

function updateBarcodes(id: string, barcodes: any) {
  return httpClient.patch(`/article/${localStorage.getItem('cid')}/${id}/barcodes`, barcodes).then(response => response.data)
}

function getTCDC(articleId: string) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/temp?articleId=${articleId}`).then(response => response.data)
}

function getArticleById(id: number) {
  return httpClient.get(`article/${localStorage.getItem('cid')}/${id}`, {
    headers: {
      "Cache-Control": "no-cache, no-store, must-revalidate"
    }
  }).then(response => response.data)
}

function getNextStatus(status: string) {
  return httpClient.get(`article/options/status/${status}/next-steps`).then(response => response.data)
}

async function findAllByProductGroup(productGroup: any) {
  return await httpClient.get(`article/${localStorage.getItem('cid')}?productGroup=${productGroup}&size=10000`).then(response => response.data.content)
}

async function updateNote(payload: {articleId: string | number, note: string}) {
  return await httpClient.patch(`article/${localStorage.getItem('cid')}/${payload.articleId}/note`, payload)
}

async function searchPartsArticles(payload: any) {
  return await httpClient.post(`/article/${localStorage.getItem('cid')}/search/vehicles`, payload).then((response) => response.data)
}

async function exportSearchByVehicle(payload: any) {
  return await httpClient.post(`/article/${localStorage.getItem('cid')}/search/vehicles/export`, payload, {
    responseType: 'blob'
  }).then((response) => response.data)
}

async function exportSearchBySelectors(payload: any) {
  return await httpClient.post(`/article/${localStorage.getItem('cid')}/search/selectors/export`, payload, {
    responseType: 'blob'
  }).then((response) => response.data)
}

async function getCurrentUserSelectors() {
  return await httpClient.get(`/article/${localStorage.getItem('cid')}/search/selectors/current`).then((response) => response.data)
}

async function getAllSelectors() {
  return await httpClient.get(`/article/${localStorage.getItem('cid')}/search/selectors`).then((response) => response.data)
}

async function updateSelectors(selectors: Array<string>) {
  return await httpClient.post(`/article/${localStorage.getItem('cid')}/search/selectors`, selectors)
}

function getAttributesById(articleId: any) {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/requirement_attributes/${articleId}`).then((response) => response.data)
}

function updateAttribute(articleId: any, payload: any) {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/requirement_attributes/${articleId}`, payload).then((response) => response.data)
}

async function addAttributeImage(formData: any) {
  const image = await httpClient.post(`/article/${localStorage.getItem('cid')}/requirement_attributes/image`, formData).then((response) => response.data)
  return `${process.env.VUE_APP_URL_FILES}${image.split('\\').join('/')}`
}

async function getAttributeImages(articleName: any) {
  const response = await httpClient.get(`/article/${localStorage.getItem('cid')}/requirement_attributes/image/${articleName}`)
  return response.data.map((image: any) => {
    return `${process.env.VUE_APP_URL_FILES}${image.split('\\').join('/')}`
  })
}

function deleteAttributeImage(articleId: any, imageName: any) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/requirement_attributes/image?articleId=${articleId}&imageName=${imageName}`)
}

function updateClone(payload: any) {
  return httpClient.patch(`/article/${localStorage.getItem('cid')}/tree/clone`, payload).then(response => response.data)
}

function updateStatusTemplate() {
  return httpClient.get(`/article/${localStorage.getItem('cid')}/status/update/template`, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function updateMultipleStatus(file: any, status: any) {
  return httpClient.patch(`/article/${localStorage.getItem('cid')}/status/update?status=${status}`, file)
}

function deleteArticles(articles: string[]) {
  return httpClient.post(`/admin/${localStorage.getItem('cid')}/articles/delete`, articles)
}

function renameArticles(formData: FormData) {
  return httpClient.post(`/admin/${localStorage.getItem('cid')}/articles/rename/file`, formData, {
    headers: {'Content-Type': 'multipart/form-data'}
  })
}

function renameArticlesTemplate() {
  return httpClient.get(`/admin/${localStorage.getItem('cid')}/articles/rename/file/template`, {
    responseType: 'blob'
  }).then((response) => response.data)
}

function findGroupArticlesByName(articleIds: string[]) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/articleIds`, articleIds).then(response => response.data)
}


export default {
  findGroupArticlesByName,
  renameArticlesTemplate,
  renameArticles,
  deleteArticles,
  updateMultipleStatus,
  updateStatusTemplate,
  updateClone,
  findArticleByName,
  findArticleByNameAndProductGroup,
  updateArticle,
  getArticles,
  changeStatus,
  getArticleById,
  getNextStatus,
  getTCDC,
  changeTempStatus,
  findAllByProductGroup,
  updateNote,
  searchPartsArticles,
  exportSearchByVehicle,
  getCurrentUserSelectors,
  getAllSelectors,
  updateSelectors,
  exportSearchBySelectors,
  updateBarcodes,
  newFindArticleByName,
  getAttributesById,
  updateAttribute,
  addAttributeImage,
  getAttributeImages,
  deleteAttributeImage,
}
