import {createApp} from 'vue';
import tooltip from '@/directives/tooltip';
import App from './App.vue';
import router from './router';
import {store} from './store'
import SocketManager from './plugins/SocketManager';

createApp(App)
  .directive('tooltip', tooltip)
  .use(router)
  .use(store)
  .use(SocketManager)
  .mount('#app');

